import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue' /* 首页 */
import Mine from '../pages/mine/index.vue' /* 我的 */
import Choose from '../pages/choose/index.vue' /* 选妃 */
import List from '../pages/choose/list.vue' /* 选妃列表 */
import Profile from '../pages/choose/profile.vue' /* 选妃详情 */
import Profile1 from '../pages/choose/profile1.vue' /* 选妃详情 */
import Video from '../pages/video/index.vue' /* 视频 */
import Game from '../pages/game/index.vue' /* 游戏 */
import Login from '../pages/login/index.vue' /* 登录 */
import Register from '../pages/login/register.vue' /* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue' /* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue' /* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue' /* 设置 */
import Infomation from '../pages/mine/Infomation.vue' /* 基本信息 */
import Setname from '../pages/mine/Setname.vue' /* 修改姓名 */
import Language from '../pages/mine/Language.vue' /* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue' /* 修改姓名 */
import Recharge from '../pages/mine/Recharge.vue' /* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue' /* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue' /* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue' /* 彩票详情 */
import Notice from '../pages/mine/Notice.vue' /* 公告 */
import PlayVideo from '../pages/video/PlayVideo' /* 视频播放页面 */
import Setbank from '../pages/mine/Setbank' /* 视频播放页面 */
import BindCard from '../pages/mine/BindCard' /* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw' /* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport' /* 个人表报 */
import GameRecord from '../pages/mine/GameRecord' /* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord' /* 提现记录 */
import Layout from '../common/Layout.vue' /* 提现记录 */
import Index from '../pages/index/index.vue' /* 提现记录 */


Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        component: Index,
        meta: {title: 'KRC'},
    },
    {
        path: '/Home',
        redirect: '/Home',
        component: Layout,
        meta: {title: 'KRC'},
        children: [
            {path: '/Home', name: 'home', component: Home, meta: {title: 'KRC'}},
            {path: '/Choose', name: 'choose', component: Choose, meta: 'KRC'},
            {path: '/List', name: 'list', component: List, meta: {title: 'KRC'}},
            {path: '/Profile', name: 'profile', component: Profile, meta: {title: 'KRC'}},
            {path: '/profile1', name: 'profile1', component: Profile1, meta: {title: 'KRC'}},
            {path: '/Mine', name: 'mine', component: Mine, meta: {title: 'KRC'}},
            {path: '/Video', name: 'video', component: Video, meta: {title: 'KRC'}},
            {path: '/XKLD', name: 'game', component: Game, meta: {title: 'KRC'}},
            {path: '/Login', name: 'login', component: Login, meta: {title: 'KRC'}},
            {path: '/Register', name: 'register', component: Register, meta: {title: 'KRC'}},
            {path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: {title: 'KRC'}},
            {path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: {title: 'KRC'}},
            {path: '/Setting', name: 'Setting', component: Setting, meta: {title: 'KRC'}},
            {path: '/Infomation', name: 'Infomation', component: Infomation, meta: {title: 'KRC'}},
            {path: '/Setname', name: 'Setname', component: Setname, meta: {title: 'KRC'}},
            {path: '/Setsex', name: 'Setsex', component: Setsex, meta: {title: 'KRC'}},
            {path: '/Language', name: 'Language', component: Language, meta: {title: 'KRC'}},
            {path: '/Recharge', name: 'Recharge', component: Recharge, meta: {title: 'KRC'}},
            {path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: {title: 'KRC'}},
            {path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: {title: 'KRC'}},
            {path: '/Lottery', name: 'Lottery', component: Lottery, meta: {title: 'KRC'}},
            {path: '/Notice', name: 'Notice', component: Notice, meta: {title: 'KRC'}},
            {path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: {title: 'KRC'}},
            {path: '/Setbank', name: 'Setbank', component: Setbank, meta: {title: 'KRC'}},
            {path: '/BindCard', name: 'BindCard', component: BindCard, meta: {title: 'KRC'}},
            {path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: {title: 'KRC'}},
            {path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: {title: 'KRC'}},
            {path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: {title: 'KRC'}},
            {path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: {title: 'KRC'}},
        ],
    },
];

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}


//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to, from, next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router

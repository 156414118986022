<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar title="Share" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="info">
        <p class="title">
          số dư hiện tại
        </p>
        <p class="value">{{ this.balance }}</p>
      </div>
      <div class="content recharge">
        <van-form @submit="onSubmit">
          <div class="form-item">
            <div class="form-item-title">Vui lòng nhập số tiền nạp</div>
            <!--            <div style="height: 65px">-->
            <!--              <van-field-->
            <!--                v-model="money"-->
            <!--                name="money"-->
            <!--                label="MXN"-->
            <!--                :placeholder="$t('recharge.input_money')"-->
            <!--              />-->
            <!--            </div>-->
            <van-cell-group>
              <van-field v-model="value" placeholder="Vui lòng nhập số tiền nạp"/>
              <div style="position: relative">
                <div style="position: absolute;top:0;left: 0;right: 0;bottom: 0;z-index: 2"
                     @click="showPicker=true"></div>
                <van-field v-model="type" label="kiểu" placeholder="Xóa để chọn loại nạp tiền"/>
              </div>
            </van-cell-group>
          </div>
          <div class="form-item">
            <!--            <div class="form-item-title">{{ $t("recharge.pay_way") }}</div>-->
            <!--            <div>-->
            <!--              <van-radio-group v-model="pay_way">-->
            <!--                <van-radio name="Mexicopay">MexicoPay</van-radio>-->
            <!--                &lt;!&ndash; <van-radio name="2">OpplePay</van-radio> &ndash;&gt;-->
            <!--              </van-radio-group>-->
            <!--            </div>-->
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit"
              >Share
              </van-button
              >
            </div>
          </div>
        </van-form>
      </div>
    </div>
    <div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
            confirm-button-text="Xác định"
            cancel-button-text="Hủy bỏ"
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {Cell, CellGroup, Field, Form, Picker, Radio, RadioGroup} from "vant";

Vue.use(Form).use(Field).use(RadioGroup).use(Radio).use(Picker);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  data() {
    return {
      balance: 0,
      pay_way: "Mexicopay",
      win_money: 0,
      type: '',
      columns: ['momo', 'vietcombank', 'vietinbankipay', 'vtpay', 'tpbank', 'acbbank', 'techcombank', 'pvbank', 'vpbank', 'vibbank', 'pppay', 'pppay', 'mbbank', 'bidv', 'b1001'],
      money: "",
      value: "",
      personalreport: {},
      showPicker: false
    };
  },
  mounted() {
    this.balance = this.$route.params.balance;
  },
  methods: {
    onConfirm(value) {
      this.type = value
      this.showPicker = false
    },
    back() {
      return window.history.back();
    },
    zf() {
      if (this.type && this.value) {
        this.$http({
          method: "get",
          url: "recharge",
          data: {type: this.type, amount: this.value}
        }).then((res) => {
          console.log(123, res)
          // if (res.code === 200) {
          //   this.personalreport = res.data;
          //   this.win_money =
          //       this.personalreport.win_money - this.personalreport.play_money;
          // } else if (res.code === 401) {
          //   this.$toast(res.msg);
          // }
        });
      }
    },
    //拉起支付
    onSubmit(values) {
      const money = values.money;
      if (money <= 0) {
        this.$toast(this.$t("reservation.money_err"));
        return;
      }
      this.$http({
        method: "post",
        data: {type: this.type, money: this.value},
        url: "recharge",
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          window.location.href = res.data.pay_url;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },

    getPersonalreport() {

    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({path: "/Login"});
    } else {
      this.getPersonalreport();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-cell__title {
  font-size: 4vw;
  font-weight: 500;
  color: #333;
}

::v-deep .van-ellipsis {
  font-size: 4vw;
  font-weight: 500;
  color: #333;
}

::v-deep .van-picker__cancel {
  font-size: 4vw;
  font-weight: 500;
  color: #999;
  padding-top: 20px;
}

::v-deep .van-picker__confirm {
  padding-top: 20px;
  font-size: 4vw;
  font-weight: 500;
  color: #999;
}

::v-deep .picker__toolbar {
  padding-top: 20px;
  font-size: 4vw;
  font-weight: 500;
  color: #999;
}

::v-deep .van-picker__toolbar {
  height: 70px !important;
}

:v-deep .van-field__control:disabled {
  font-size: 4vw;
  color: #333 !important;
}

::v-deep .van-field__control {
  font-size: 4vw;
  font-weight: 500;
}

.container .header {
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}

.recharge {
  padding: 10px 30px;
}

.van-cell {
  line-height: 65px !important;
}

.van-button {
  height: 87px !important;
}

.van-button__text {
  color: #fff !important;
}

/deep/ .van-radio__icon {
  font-size: 30px !important;
}

/deep/ .van-radio__label {
  margin-left: 25px !important;
  font-size: 35px !important;
}

/deep/ .van-radio {
  height: 65px !important;
}

.form-item {
  margin-top: 40px;
}

.form-item-title {
  font-size: 36px;
  font-weight: bold;
  color: #999;
  margin-bottom: 20px;
}

.recharge span {
  font-size: 4vw;
  color: #868686;
  font-weight: 500;
}

.container .header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  background: linear-gradient(90deg, #500cfd, #9e9ce7);
  margin: auto;
}

.container .header .info .title {
  font-size: 25px;
  color: #e5e5e5;
}

.container .header .info .value {
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}

.container .header .info .tip {
  font-size: 30px;
  color: #e5e5e5;
}

.container .content {
  flex: 1;
  background: #f2f2f5;
}
</style>

<template>
  <div id="container" @click="toLottery">
    <div class="vis_wrap sub06">
      <div class="titleBox">
        <strong class="tit"><span>Vì hạnh phúc của nông thôn trong 100 năm đã qua và 100 năm tới</span></strong>
        <em>Tổng công ty Nông thôn Hàn Quốc luôn luôn nỗ lực.</em>
      </div>
    </div>
    <div class="location">
      <ol class="wrap">
        <li><a class="home">HOME</a></li>
        <li><a>Giới thiệu về công ty</a></li>
        <li><a>Thông tin tuyển dụng</a></li>
        <li><a>Thông tin tuyển dụng</a></li>
        <li><a>Thông tin tuyển dụng nhân viên mới</a></li>
      </ol>
    </div>
    <div id="sub_wrap">
      <div class="wrap">
        <section id="side" class="side">
          <h2><span class="side_subject">Giới thiệu về công ty</span></h2>
          <nav class="lnb">
            <ul>
              <li class="depth1"><a>CEO</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Lời chào của CEO</a>
                  </li>

                  <li><a>Tiểu sử CEO</a>
                  </li>

                  <li><a>Chuyến đi khảo sát nông nghiệp của CEO</a>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a>Tình hình chung</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Nhiệm vụ/Chức năng</a>
                  </li>

                  <li><a>Mục tiêu quản lý</a>
                  </li>

                  <li><a>Tổ chức/Số lượng nhân viên</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Tổ chức/Số lượng</a></li>
                      <li><a>Tìm kiếm nhân viên</a></li>
                    </ul>
                  </li>

                  <li><a>Tình hình các văn phòng khu vực/chi nhánh</a>
                  </li>

                  <li><a>Lịch sử phát triển</a>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a>Quản lý mở</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Giới thiệu về công khai quản lý</a>
                  </li>

                  <li><a>Cấu trúc công khai quản lý</a>
                  </li>

                  <li><a>Chi phí thực hiện công việc</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Lãnh đạo cơ quan
                        Chi phí thực hiện công việc</a></li>
                      <li><a>Chi phí thực hiện công việc của thanh tra</a>
                      </li>
                      <li><a>Giám đốc điều hành
                        Chi phí thực hiện công việc</a></li>
                      <li><a>Chức danh đặc biệt
                        Chi phí thực hiện công việc</a></li>
                      <li><a>Trưởng văn phòng khu vực
                        Chi phí thực hiện công việc</a></li>
                    </ul>
                  </li>

                  <li><a>Ủy ban xem xét dự án tự chủ</a>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a>Luật pháp và
                내규</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Luật pháp</a>
                  </li>

                  <li><a>Quy định nội bộ</a>
                  </li>

                  <li><a>Hướng dẫn và quy trình</a>
                  </li>

                  <li><a>Thông báo dự thảo sửa đổi quy định nội bộ</a>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a>ESG
                Quản lý</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Chiến lược triển khai</a>
                  </li>

                  <li><a>Thực hành quản lý ESG
                    Tuyên bố</a>
                  </li>

                  <li><a>Báo cáo quản lý bền vững</a>
                  </li>

                  <li><a>Tăng trưởng đồng hành</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Tổng quan về tăng trưởng đồng hành</a>
                      </li>
                      <li><a>Chế độ chia sẻ thành quả</a></li>
                      <li><a>Hỗ trợ kỹ thuật</a></li>
                      <li><a>Phát triển nông nghiệp quốc tế
                        Dự án hỗ trợ</a></li>
                    </ul>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a>Quản lý đạo đức và quyền con người</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Quản lý đạo đức</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Tin nhắn của Giám đốc điều hành</a>
                      </li>
                      <li><a>Khái niệm về quản lý đạo đức</a>
                      </li>
                      <li><a>Mục tiêu triển khai và
                        Chiến lược</a></li>
                      <li><a>Quản lý đạo đức
                        Hệ thống thực hiện</a></li>
                      <li><a>Kết quả triển khai</a></li>
                      <li><a>Thư viện tài liệu quản lý đạo đức</a>
                      </li>
                      <li><a>Quy tắc đạo đức</a></li>
                      <li><a>Hợp đồng liêm chính nhiệm vụ của cán bộ điều hành</a>
                      </li>
                    </ul>
                  </li>

                  <li><a>Quản lý quyền con người</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Hệ thống quản lý quyền con người</a>
                      </li>
                      <li><a>Quy tắc quản lý quyền con người</a>
                      </li>
                      <li><a>Thư viện tài liệu quản lý quyền con người</a>
                      </li>
                    </ul>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a
                  title="An toàn/Thảm họa">An toàn/Ứng phó thảm họa</a>
                <ul class="depth2" style="display: none;">
                  <li><a>Chính sách quản lý an toàn và sức khỏe</a>
                  </li>

                  <li><a>Sơ đồ tổ chức an toàn/ứng phó thảm họa</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Hệ thống quản lý an toàn</a>
                      </li>
                      <li><a>Hệ thống quản lý thảm họa</a>
                      </li>
                    </ul>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a>giao lưu xã hội</a>
                <ul class="depth2" style="display: none;">
                  <li><a>làng chài nông thôn
                    Hoạt động nạp lại niềm vui</a>
                  </li>

                  <li><a>Nạp tiền hạnh phúc KRC
                    Giới thiệu nhóm tình nguyện</a>
                  </li>

                  <li><a>Các lĩnh vực hoạt động chính</a>
                    <ul class="depth3" style="display: none;">
                      <li><a>Nâng cao hạnh phúc</a></li>
                      <li><a>giấc mơ bài hát vui vẻ</a></li>
                      <li><a>Chia sẻ hạnh phúc</a></li>
                    </ul>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a
                  class="active">Thông tin tuyển dụng</a>
                <ul class="depth2">
                  <li><a>Thông tin tuyển dụng</a>
                    <ul class="depth3">
                      <li><a
                          class="active">Thông tin tuyển dụng nhân viên mới</a></li>
                      <li><a>Thông tin tuyển dụng thực tập sinh</a>
                      </li>
                    </ul>
                  </li>

                  <li><a>Số người thân được tuyển dụng
                    mở</a>
                  </li>

                </ul>
              </li>
              <li class="depth1"><a
                  title="Chỉ đường">Chỉ đường</a>
              </li>
            </ul>
          </nav>
        </section>
        <section id="right_wp">
          <div class="sub_info">
            <div class="sub_title">
              <h3>Đăng ký hồ sơ</h3>
            </div>
          </div>
          <div id="contents">
            <div class="tab ">
              <h4 class="selected">Thông tin tuyển dụng nhân viên mới</h4>
              <strong class="blind">Chọn menu tab</strong>
              <ol class="">
                <li class="on"><a>Tân binh
                  Thông tin tuyển dụng</a></li>
                <li><a>Thông tin tuyển dụng thực tập sinh</a></li>
              </ol>
            </div>


            <div class="Rcon Recruitment">
              <h4 class="tit">tài năng lý tưởng</h4>
              <div class="textBox MR20 mb_1">
                <h5 class="tit">Tầm nhìn của tập đoàn</h5>
                <p>Làng nông ngư vui vẻ cùng KRC</p>
              </div>
              <div class="textBox mb_1">
                <h5 class="tit">Tài năng lý tưởng cho ngành xây dựng</h5>
                <p>Tài năng sáng tạo góp phần tạo giá trị ở nông thôn</p>
              </div>
              <div class=" imgBox_w">
                <p class="Recr_text">
                  KRC tạo nên giá trị góp phần vào sáng tạo nguồn nhân lực</p>
                <ul class="Society_con01">
                  <li>tính chuyên nghiệp</li>
                  <li>kĩ năng giao tiếp</li>
                  <li>Ấn tượng của khách hàng</li>
                  <li>tính đổi mới</li>
                </ul>
              </div>
              <h4 class="tit">Lĩnh vực tuyển dụng</h4>
              <ul class="li_01">
                <li><strong class="p_navy">Cấp 7 (hợp đồng không thời hạn)</strong>
                  <br>- nhân viên văn phòng, kỹ thuật viên
                </li>
              </ul>
              <h4 class="tit">Trình độ chuyên môn</h4>
              <ul class="li_01">
                <li>Không có giới hạn về giới tính, độ tuổi, trình độ học vấn, v.v.</li>
                <li>Sàng lọc khuyết tật
                  <ul class="li_02">
                    <li>Người khuyết tật được đăng ký theo 「Đạo luật xúc tiến việc làm và phục hồi nghề nghiệp cho người khuyết tật」</li>
                  </ul>
                </li>
              </ul>

              <h4 class="tit">Thủ tục tuyển chọn</h4>
              <ol class="pro_con mb_1">
                <li>
                  <span>Sàng lọc tài liệu</span>
                </li>
                <li>
                  <span>Bai kiểm tra Viêt</span>
                </li>
                <li>
                  <span>Quá trinh phỏng vấn</span>
                </li>
              </ol>


              <ul class="li_01">
                <li>Chỉ những người vượt qua từng giai đoạn sàng lọc mới đủ điều kiện để thực hiện giai đoạn tiếp theo.</li>
              </ul>
              <h4 class="tit">Thông báo tuyển dụng nhân viên lớp 7 năm 2024</h4>
              <div class="btn_l">
                <a
                    class="btn02 mb_2" title="Tải xuống">
                  <span>Thông báo tuyển dụng nhân viên lớp 7 năm 2024 PDF</span></a></div>
              <h4 class="tit">Đến Tuyển dụng nhân viên lớp 7 năm 2024</h4>
              <div class="btn_l">
                <a class="btn02 mb_2">
                  <span>Tuyển dụng nhân viên cấp 7 năm 2024</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toLottery() {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/Home'})
      }
    },
  },
  mounted() {

  },
  created() {
  }
}

</script>

<style scoped>
</style>

<template>
  <div id="app">
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from './Footer'

export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status: 0
    };
  },
  methods: {},
  created() {
  }
}
</script>

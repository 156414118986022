<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data() {
    return {
      status: 0
    };
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        // if (!localStorage.getItem('token')) {
        //   this.$router.push({path: '/'})
        // } else {
        //   this.$router.push({path: '/Home'})
        // }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  created() {
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}

body .van-toast .van-toast__icon {
  font-size: 50px;
}

*, :after, :before {
  box-sizing: border-box;
}
</style>

<template>
  <van-tabbar
      v-if="show"
      v-model="active"
      active-color="#000"
      :border="true"
      inactive-color="#000"
      style="z-index: 9999"
  >
    <!-- 首页 -->
    <van-tabbar-item to="/Home">
      <span>{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img
            :src="!props.active ? '/img/footer/sy.png' : '/img/footer/sys.png'"
            :alt="$t('foorter.index')"
            v-show="show !== 0"
        />
        <img
            :src="props.active ? '/img/footer/sy.png' : '/img/footer/sys.png'"
            :alt="$t('foorter.index')"
            :class="$t('foorter.index')"
            style="height: 4rem"
            v-show="show === 0"
        />
      </template>
    </van-tabbar-item>

    <!-- 中间 -->
    <!--    <van-tabbar-item to="/Choose">-->
    <!--      <span></span>-->
    <!--      <template #icon="props">-->
    <!--        <img class="tui" -->
    <!--          :src="props.active ? '/img/footer/logo_icon.png' : '/img/footer/logo_icon.png'"-->
    <!--        />-->
    <!--      </template>-->
    <!--    </van-tabbar-item>-->
    <!-- 视频 -->
    <!-- 预约 -->
    <van-tabbar-item  to="/Video">
      <span>Giới thiệu</span>
      <template #icon="props">
        <img

            :src="!props.active ? '/img/footer/112.png' : '/img/footer/beauty.52660ad1s.png'"
            :alt="$t('foorter.subscribe')"
            v-show="show !== 1"
        />
        <img
            :src="props.active ? '/img/footer/112.png' : '/img/footer/beauty.52660ad1s.png'"

            :alt="$t('foorter.subscribe')"
            :class="$t('foorter.subscribe')"
            style="height: 4rem"
            v-show="show === 1"
        />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/XKLD">
      <span>Doanh nghiệp</span>
      <template #icon="props">
        <img
            :src="!props.active ? '/img/footer/sp.png' : '/img/footer/sps.png'"
            :alt="$t('foorter.video')"
            v-show="show != 2"
        />
        <img
            :src="props.active ? '/img/footer/sp.png' : '/img/footer/sps.png'"

            :alt="$t('foorter.video')"
            :class="$t('foorter.video')"
            style="height: 4rem"
            v-show="show == 2"
        />
      </template>
    </van-tabbar-item>


    <!-- 我的 -->
    <van-tabbar-item to="/Mine">
      <span>Nhân viên</span>
      <template #icon="props">
        <img
            :src="props.active ? '/img/footer/myed.jpg' : '/img/footer/my.jpg'"
            :alt="$t('foorter.my')"
            v-show="show != 3"
        />
        <img
            :src="props.active ? '/img/footer/myed.jpg' : '/img/footer/my.jpg'"
            :alt="$t('foorter.my')"
            :class="$t('foorter.my')"
            style="height: 4rem"
            v-show="show == 3"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  methods: {},
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 1;
        this.show = true;
      }else if (to.name == "game") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 3;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 3;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 75px;
.van-tabbar {
  height: @tabbar-height;
}

.van-tabbar-item__icon img {
  height: @tabbar-img;
}

.van-tabbar-item {
  font-size: 26px;
}

.tui {
  width: 4rem;
  height: 4rem !important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}

[class*="van-hairline"]::after {
  border: none !important;
}
</style>

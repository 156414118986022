<template>
  <article id="content" class="content">

    <section class="sec01">
      <div class="wRap">
        <div class="visWr clearfix">
          <div class="txtWr"><img src="/img/home/sg.png" alt="Tình yêu nông thôn Giá trị xanh ESG 2030">
            <p class="big">Nơi thành công dẫn lối !</p>
            <u class="small">KRC, tập đoàn đại chúng toàn cầu dẫn đầu ESG trong lĩnh vực nông nghiệp</u>
          </div>
          <div class="imgWr">
            <van-swipe @change="swipechange" :show-indicators="false" class="my-swipe" :autoplay="3000"
                       indicator-color="white">
              <van-swipe-item>
                <img src="/img/home/main_01.jpg" alt="Xem trước hình ảnh tiếp theo">
              </van-swipe-item>
              <van-swipe-item>
                <img src="/img/home/main_02.jpg" alt="Xem trước hình ảnh tiếp theo">
              </van-swipe-item>
              <van-swipe-item>
                <img src="/img/home/main_03.jpg" alt="Xem trước hình ảnh tiếp theo">
              </van-swipe-item>
              <van-swipe-item>
                <img src="/img/home/main_04.jpg" alt="Xem trước hình ảnh tiếp theo">
              </van-swipe-item>
              <van-swipe-item>
                <img src="/img/home/main_05.jpg" alt="Xem trước hình ảnh tiếp theo">
              </van-swipe-item>
            </van-swipe>
            <div class="ctrlWr">
              <strong class="first slide1_num" lang="en">0{{ index }}</strong>
              <a class="pre slide1-prev slick-arrow">trước</a>
              <a class="next slide1-next slick-arrow">Kế tiếp</a>
              <strong class="last" lang="en">05</strong>
              <div class="ppause">
                <a class="play sec01_slick_play" style="display:none;">chơi</a>
                <!--                <a class="pause sec01_slick_pause">dừng lại</a>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec00">
      <div class="banWr">
        <h2 class="tit">KRC Thông báo Tuyển dụng</h2>
        <div class="banners">
          <div class="listWr krc_mini slick-initialized slick-slider">
            <van-swipe @change="swipechange1" :show-indicators="false" class="my-swipe" :autoplay="3000"
                       indicator-color="white">
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/be590e87-206e-422b-afcd-d3d7e62c1d08.jpg"
                    alt="Báo cáo lãng phí ngân sách Kookmin Shinmungo www.epeople.go.kr Điện thoại chuyên báo cáo lãng phí ngân sách 1577-1242">
              </van-swipe-item>
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/db6ded81-7940-4db7-a796-810938154a1a.png"
                    alt="Thông báo tuyển dụng nhóm nhân lực chuyên gia cho Dự án Hợp tác Nông nghiệp Quốc tế (ODA) 2024">

              </van-swipe-item>
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/21b8b8b4-fc91-4c4b-bad4-61169e6e0f68.jpg"
                    alt="Thông báo tuyển dụng nhóm nhân lực chuyên gia cho Dự án Hợp tác Nông nghiệp Quốc tế (ODA) 2024">
              </van-swipe-item>
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/bf765e36-19dd-49bd-a15c-3938888e5443.png"
                    alt="Thông báo tuyển dụng nhóm nhân lực chuyên gia cho Dự án Hợp tác Nông nghiệp Quốc tế (ODA) 2024">
              </van-swipe-item>
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/501fe262-ac5e-4c24-8ed9-ff56c7f4097f.jpg"
                    alt="Thông báo tuyển dụng nhóm nhân lực chuyên gia cho Dự án Hợp tác Nông nghiệp Quốc tế (ODA) 2024">
              </van-swipe-item>
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/fc53b0a3-b62f-4d43-b1ca-ef427729bcd9.jpg"
                    alt="Thông báo tuyển dụng nhóm nhân lực chuyên gia cho Dự án Hợp tác Nông nghiệp Quốc tế (ODA) 2024">
              </van-swipe-item>
              <van-swipe-item>
                <img
                    src="/planweb/upload/402880863251923e01325193a7480005/popup/original/b4648035-a5d8-4d37-abda-bac643d8b47c.jpg"
                    alt="Thông báo tuyển dụng nhóm nhân lực chuyên gia cho Dự án Hợp tác Nông nghiệp Quốc tế (ODA) 2024">
              </van-swipe-item>
            </van-swipe>
          </div>
          <div class="countWr clearfix" style="display: flex;align-items: center">
            <div class="count fl"><b class="krc_mini_count">{{index1}}</b><small>/</small><b class="sum">7</b></div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec03">
      <div class="wRap">
        <h2 class="tit">Yêu Thích</h2>
        <ul class="quick clearfix">
          <li><a><span>Thông báo thông tin trước</span></a></li>
          <li><a><span>Công bố thông tin quản lý</span></a></li>
          <li><a><span>Hợp đồng đàm phán</span></a></li>
          <li><a><span>Trung tâm báo cáo KRC</span></a></li>
          <li><a><span>Dịch vụ dân sự điện tử</span></a></li>
          <li><a><span>Nghiên cứu nông thôn</span></a></li>

          <li><a><span>chứng nhận BF</span></a></li>
          <li><a><span>Trung tâm chẩn đoán an toàn</span></a></li>
        </ul>
      </div>
    </section>

    <section class="sec04">
      <div class="wRap clearfix">
        <div class="news fl">
          <h2 class="tit" style="color: white"><b>Có gì mới</b></h2>
          <ul class="tab">

            <li class="on"><a> thông báo</a></li>
            <li><a>thông cáo báo chí</a></li>

            <li><a>Thông báo đấu thầu</a></li>
          </ul>


          <div class="list_notice clearfix">
            <div class="post">
              <strong class="cate1">
                Trụ sở Chungnam</strong>
              <a>
                Tiết lộ dữ liệu đánh giá chồng chéo công việc trong quá trình đánh giá năng lực thực hiện dự án đối với
                kế hoạch thành lập công viên và dịch vụ thiết kế chi tiết cho dự án lắp đặt cơ sở sử dụng và bảo tồn môi
                trường tự nhiên Obongji</a>
              <span lang="en" class="small">2024-08-02</span>
            </div>
            <div class="post">
              <strong class="cate1">
                trụ sở chính</strong>
              <a>
                Tổng công ty Cộng đồng Nông thôn Hàn Quốc Trụ sở chính khu vực Cảnh sát Thông báo liên kết công khai
                theo thời gian thực để đánh giá cuộc thi đề xuất dịch vụ thiết kế kiến trúc cho “Dự án xây dựng cơ sở
                sống cơ bản theo pháp luật”</a>
              <span lang="en" class="small">2024-08-02</span>
            </div>
            <div class="post">
              <strong class="cate1">
                Trụ sở chính của tỉnh Kyungbuk</strong>
              <a>
                Seokpo-myeon Dịch vụ thiết kế dự án xây dựng cơ sở sống cơ bản Kết quả sàng lọc cuộc thi</a>
              <span lang="en" class="small">2024-08-02</span>
            </div>
          </div>

          <div class="list_pressrelease clearfix" style="display:none;">
            <div class="post">
              <strong class="cate1">Hợp tác đối ngoại</strong>
              <a>Cộng đồng nông thôn và ngư dân hỗ trợ 'Bữa ăn lành mạnh cho học sinh tiểu học' tại một trung tâm chăm
                sóc chung của tổ chức công</a>
              <span lang="en" class="small">2024-07-25</span>
            </div>
            <div class="post">
              <strong class="cate1">Biến đổi khí hậu liên quan</strong>
              <a>Tổng công ty cộng đồng nông thôn, “Ứng phó lũ lụt, huy động mọi nhân lực, trang thiết bị sẵn có”</a>
              <span lang="en" class="small">2024-07-24</span>
            </div>
            <div class="post">
              <strong class="cate1">Khuyến mãi bên ngoài</strong>
              <a>「Trung tâm H2O」, ‘Chiến tranh trên bộ quốc gia Hàn Quốc’Nhận được giải thưởng Giám đốc của Viện Nghiên
                cứu Định cư Con người Hàn Quốc</a>
              <span lang="en" class="small">2024-07-18</span>
            </div>
          </div>


        </div>
        <div class="photo fr">
          <h2 class="tit" style=""><b>Tin ảnh</b></h2>
          <div class="photoWr clearfix slick-initialized slick-slider">
            <div class="slick-list draggable">
              <div class="slick-track" style="opacity: 1; width: 30000px; transform: translate3d(0px, 0px, 0px);">
                <div class="slick-slide slick-cloned" data-slick-index="-3" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706750682531_KJS_0143.JPG"
                             alt="">
                        <strong class="title">Báo cáo công việc của Giám đốc điều hành Tập đoàn Dự án Sông Yeongsan năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="-2" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706749724735_KJS_9495.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở chính khu vực Jeju năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706748854608_KJS_9255.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở chính khu vực Kyunggi năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-current slick-active" data-slick-index="0" aria-hidden="false">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706771768136_KJS_0985.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở khu vực Jeonnam năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-active" data-slick-index="1" aria-hidden="false">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706751403322_KJS_0639.JPG"
                             alt="">
                        <strong class="title">Báo cáo công việc của Giám đốc điều hành Tập đoàn Kinh doanh Geumgang
                          2024, Tập đoàn Kinh doanh Saemangeum và Tập đoàn Kinh doanh Khu phức hợp Công nghiệp
                          Saemangeum</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-active" data-slick-index="2" aria-hidden="false">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706754093559_KJS_0318.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở khu vực Jeonbuk năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide" data-slick-index="3" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706750682531_KJS_0143.JPG"
                             alt="">
                        <strong class="title">Báo cáo công việc của Giám đốc điều hành Tập đoàn Dự án Sông Yeongsan năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide" data-slick-index="4" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706749724735_KJS_9495.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở chính khu vực Jeju năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide" data-slick-index="5" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706748854608_KJS_9255.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở chính khu vực Kyunggi năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="6" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706771768136_KJS_0985.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở khu vực Jeonnam năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="7" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706751403322_KJS_0639.JPG"
                             alt="">
                        <strong class="title">Báo cáo công việc của Giám đốc điều hành Tập đoàn Kinh doanh Geumgang
                          2024, Tập đoàn Kinh doanh Saemangeum và Tập đoàn Kinh doanh Khu phức hợp Công nghiệp
                          Saemangeum</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="8" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706754093559_KJS_0318.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở khu vực Jeonbuk năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="9" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706750682531_KJS_0143.JPG"
                             alt="">
                        <strong class="title">Báo cáo công việc của Giám đốc điều hành Tập đoàn Dự án Sông Yeongsan năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="10" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706749724735_KJS_9495.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở chính khu vực Jeju năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="slick-slide slick-cloned" data-slick-index="11" aria-hidden="true" tabindex="-1">
                  <div>
                    <div class="post" style="width: 100%; display: inline-block;">
                      <a>
                        <img src="https://pr.ekr.or.kr/files/upload/article/1/thumbnail_1706748854608_KJS_9255.JPG"
                             alt="">
                        <strong class="title">Báo cáo kinh doanh của Giám đốc điều hành Trụ sở chính khu vực Kyunggi năm
                          2024</strong>
                        <span lang="en">2024-02-01</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sec05">
      <div class="wRap clearfix">
        <div class="titBox fl">
          <h2 class="tit">Lĩnh vực kinh doanh chính </h2>
          <h3 class="business_tit tit">Xây dựng cơ sở hạ tầng thực phẩm</h3>
          <p class="business_txt txt">Tổng công ty Cộng đồng Nông thôn Hàn Quốc là một tổ chức quản lý 'đất' và 'nước'
            với lịch sử 100 năm và là tổ chức công cộng chuyên về khu vực nông thôn chịu trách nhiệm cung cấp lương thực
            cho 50 triệu người.</p>
          <br>

          <ul class="list_tag">
            <li>#phát triển tài nguyên nước</li>
            <li>#Phát triển đất nông nghiệp</li>
            <li>#Hợp tác nông nghiệp nước ngoài</li>
            <li>#Tạo trang trại thông minh</li>
          </ul>
        </div>
        <div class="imgBox fl">
          <div class="imgWr clearfix">
            <ul class="img_list slick-initialized slick-slider">
              <div class="slick-list draggable">
                <div class="slick-track" style="opacity: 1; width: 20000px; transform: translate3d(0px, 0px, 0px);">
                  <div class="slick-slide slick-cloned" data-slick-index="-1" aria-hidden="true" tabindex="-1">
                    <div>
                      <li class="on" style="width: 100%; display: inline-block;"><a tabindex="0"><img
                          src="/img/home/biz_01.jpg"
                          alt="Ảnh đại diện về việc tạo lập và duy trì cơ sở sản xuất nông ngư nghiệp - Phát triển nông nghiệp toàn diện quy mô lớn"></a>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <div class="img_ctrl">
            <ul lang="en">

              <li class="on"><a>1</a></li>
              <li><a>2</a></li>
              <li><a>3</a></li>
              <li><a>4</a></li>
              <!--li><a >5</a></li>
              <li><a >6</a></li-->
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="sec06">
      <div class="wRap clearfix">
        <div class="titBox fl">
          <h2 class="tit">Cẩm nang </h2>
          <div class="letterWr clearfix">
            <div class="txt fl">
              <h3 class="titC">bản tin KRC</h3>
              <strong class="titC">
                <span lang="en">08</span><small>số thứ hai</small>
              </strong>
              <a class="btnGo" title="Xem chi tiết">Tìm hiểu thêm</a>
            </div>
            <div class="img fl">
              <a title="Xem chi tiết"><img
                  src="https://pr.ekr.or.kr/files/upload/article/19/thumbnail_1722472555684_8C6D4D638 D45CC9C0.jpg"
                  alt="Bản tin KRC số tháng 8"></a>
            </div>
          </div>
        </div>
        <div class="quickWr fl clearfix">

          <div class="quick item01">
            <h3><a>Hệ thống giá trị quản lý</a></h3>
            <p>Bạn có thể kiểm tra sứ mệnh, tầm nhìn và giá trị cốt lõi của Tập đoàn Cộng đồng Nông thôn Hàn Quốc.</p>
          </div>

          <div class="quick item01">
            <h3><a>bảng thông báo ảnh</a></h3>
            <p>Kiểm tra các tài liệu ảnh khác nhau, bao gồm cả phong cảnh nông thôn.</p>
          </div>
          <div class="quick item01">
            <h3><a>Báo cáo an toàn</a></h3>
            <p>Chuyển sang báo cáo các tình huống rủi ro về an toàn cho cơ quan hành chính, v.v.</p>
          </div>
          <div class="quick item01">
            <h3><a>Báo cáo ô nhiễm nước</a></h3>
            <p>Vui lòng báo cáo bất kỳ hành vi xả chất ô nhiễm nước bất hợp pháp nào từ cơ sở hạ tầng nông nghiệp.</p>
          </div>
          <div class="quick item01">
            <h3><a>ý kiến của khách hàng</a></h3>
            <p>Bạn có thể nhận được thông tin liên quan đến công việc xây dựng bằng cách hỏi tùy theo lĩnh vực.</p>
          </div>
          <div class="quick item01">
            <h3><a>Đề xuất của khách hàng</a></h3>
            <p>Tập đoàn Cộng đồng Nông thôn Hàn Quốc luôn lắng nghe ý kiến đóng góp của khách hàng.</p>
          </div>
        </div>
      </div>
    </section>

    <div style="height: 60px;"></div>
  </article>
</template>

<script>
export default {
  data() {
    return {index: 1, index1: 1};
  },
  methods: {
    toLottery() {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/Home'})
      }
    },
    swipechange(e) {
      this.index = e + 1
    }, swipechange1(e) {
      this.index1 = e + 1
    }
  },
  mounted() {

  },
  created() {
  }
}

</script>

<style scoped>
</style>

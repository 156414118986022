<template>
  <div class="page">
    <van-nav-bar title="Thông Tin Xe" class="nav-bar" fixed>
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <van-icon name="bookmark-o" :color="xuanfeidata['is_favorite']==0?'#fff':'#ff0000'" @click="dingyue"/>
      </template>
    </van-nav-bar>
    <div style="height: 52px"></div>
    <van-swipe ref="mySwipe" :autoplay="3000" indicator-color="white" style="height: 270px;">
      <template v-for="(item, index) in xuanfeidata['conver']">
        <van-swipe-item :key="index">
          <img :src="item" alt="" style="width: 100%;height: 100%;">
        </van-swipe-item>
      </template>
    </van-swipe>
    <div style="grid-template-columns: repeat(4,minmax(0,1fr));display: grid;gap: 3px">
      <template v-for="(item, index) in xuanfeidata['conver']">
        <div @click="onItem(index)" style="height: 70px;border: 1px solid rgb(153, 153, 153);" :key="index">
          <img :src="item" class="w-full h-full">
        </div>
      </template>
    </div>
    <div style="padding: 15px;background: white">
      <div style="
   font-weight: 700;
    font-size: 23px
 ">
        {{ xuanfeidata['title'] }}
      </div>
      <div style="margin-top: 15px">
        <van-rate v-model="xuanfeidata['rating']" :size="20"
                  color="#ffd21e"/>
      </div>
      <div style="margin-top: 10px;font-size: 17px;color: rgb(153, 153, 153);margin-bottom:15px">
        {{ xuanfeidata['intro'] }}
      </div>
      <template v-for="(item,index) in xuanfeidata['conver']">
        <div
            :key="index"
            style="width: 100%; margin-bottom: 10px;">
          <img
              :src="item"
              style="width: 100%; height: 250px;"></div>
      </template>
    </div>
    <div style="height: 60px;"></div>
    <div v-if="this.xuanfeidata['is_favorite']==1" class="flex-col flex"
         style="height: 60px; color: white; background: rgb(3, 164, 81); padding: 10px; z-index: 9999; bottom: 0px; position: fixed; width: 100%;">
      <div class="flex-1 flex items-center">Đã lưu xong</div>
      <div class="flex-1 flex items-center justify-end">Mở Trang Đã Lưu</div>
    </div>
  </div>
</template>

<script>
import {instance} from "@/http";
import {Base64} from "js-base64";
import {Toast} from 'vant';

export default {
  data() {
    return {
      xuanfeidata: {},
      value: 3
    };
  },
  methods: {
    back() {
      this.$router.back()
    },
    onItem(index) {
      this.$refs.mySwipe.swipeTo(index)
    },
    getxuanfeidata() {
      instance.get('/car/getList', {params: {id: this.$route.query.id}}).then(res => {
        this.xuanfeidata = JSON.parse(Base64.decode(res.data.data))
        console.log(this.xuanfeidata)
      })
    },
    dingyue() {
      if (this.xuanfeidata['is_favorite'] == 0) {
        instance.get('/car/doSave', {params: {id: this.$route.query.id}}).then(res => {
          this.getxuanfeidata()
          Toast(res.data.msg);
        })
      } else {
        instance.get('/car/doRemove', {params: {id: this.$route.query.id}}).then(res => {
          this.getxuanfeidata()
          Toast(res.data.msg);
        })
      }

    }
  },
  created() {
    this.getxuanfeidata();
  }
};
</script>

<style>
.flex-col {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: end;
}

.container {
  display: inline-block;
}

.box {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 6.25rem;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.name {
  font-size: 1.125rem;
}

.title {
  font-size: 0.625rem;
}

.button {
  width: 10rem;
  height: 2.5rem;
  font-size: 0.9375rem;
  margin-top: 0.625rem;
}
</style>

<template>
  <div class="main-wrap">
    <div class="special">
      <div class="inner">
        <h2 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><p>KRC SPECIAL</p></h2>
        <a class="img-box">
          <div class="img aos-init aos-animate" data-aos="fade-up" data-aos-delay="100"><img
              src="img/main/img_special.jpg" alt="krc special"></div>
          <div class="info">
            <h3 data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><small>기술 혁신 시리즈</small>
              <p>
                cấp nước nông nghiệp<br>
                để ổn định<br>
                Người giám hộ cơ sở hạ tầng KRC</p>
            </h3>
            <div class="txt aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">Do khủng hoảng khí hậu, lượng mưa lớn và hạn hán nghiêm trọng đang trở nên thường xuyên hơn.
              Tầm quan trọng của việc quản lý nước ngày càng tăng hơn bao giờ hết. Tình hình cũng không khác gì trong nông nghiệp, nơi mà sự thành công hay thất bại của một năm canh tác phụ thuộc vào việc quản lý nước. Tầm quan trọng của nước nông nghiệp ngày càng tăng. Tổng công ty Cộng đồng Nông thôn Hàn Quốc (KRC)
              Hiện nay, nó quản lý hơn 10.000 cơ sở hạ tầng sản xuất nông nghiệp và chịu trách nhiệm cung cấp nước nông nghiệp. Để cung cấp nước nông nghiệp ổn định, Phòng Kinh doanh Cơ sở Hạ tầng KRC là bộ phận chịu trách nhiệm vận hành các công trình cấp nước nông nghiệp như hồ chứa, ao canh tác và giếng ống.
              Kiểm tra các cơ sở liên quan như tường chắn sóng và kè hồ chứa.
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="intro">
      <div class="inner">
        <h2 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><p>phần giới thiệu</p></h2>
        <ul>
          <li data-aos="fade-up" data-aos-delay="100" class="aos-init aos-animate">
            <a>
              <div class="img"><img src="img/main/img_intro1.jpg"></div>
              <p>Kỳ nghỉ hè mát mẻ ở nông thôn</p>
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate">
            <a>
              <div class="img"><img src="img/main/img_intro2.jpg"></div>
              <p>giải trí rừng xanh
                Hồ chứa nước Gangneung Eonbyeol </p>
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate">
            <a>
              <div class="img"><img src="img/main/img_intro3.jpg"></div>
              <p>Sự khởi đầu của mùa thu,
                Thời điểm hạt chín tốt

              </p>
            </a>
          </li>
          <li data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate">
            <a>
              <div class="img"><img src="img/main/img_intro4.jpg"></div>
              <p>Khi bạn cần một dấu phẩy trong cuộc sống
                Chuyến đi chữa bệnh Wando
              </p>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="dream">
      <div class="inner">
        <div class="img-box">
          <div class="txt-box">
            <h2 data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><strong>꿈</strong>터</h2>
            <div class="t1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">Đóng cửa trường học như một chất xúc tác để ngăn chặn sự tuyệt chủng ở nông thôn
            </div>
            <a class="t2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="400">Hampyeong-gun <br>
              Về nông thôn, nông thôn, trung tâm hỗ trợ loại tạm trú
            </a>
            <ul data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate">
              <li><a>Nhân vật chính của sự hài lòng của khách hàng<br>
                Chi nhánh Jinju Sancheong Giám đốc chi nhánh Sancheong Park Se-eun
              </a></li>
              <li><a>Gieo mầm hy vọng trong vườn hạnh phúc</a></li>
              <li><a>Hỗ trợ tài chính phù hợp cho nông dân trẻ <br>
                Ở đó có gì vậy?</a></li>
            </ul>
          </div>
          <div class="img"><img src="img/main/img_dream.jpg" alt="mơ"></div>
        </div>
      </div>
    </div>

    <div class="life">
      <div class="inner">
        <h2 class="mobile-text-left aos-init aos-animate" data-aos="fade-up" data-aos-delay="300"><strong>mạng sống</strong>địa điểm
        </h2>
        <ul>
          <li data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><a>
            <div class="img"><img src="img/main/img_life1.jpg">
              <p>Sự lãng mạn của du lịch nông thôn<br>ý tuscany</p></div>
          </a></li>
          <li data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><a>
            <div class="img"><img src="img/main/img_life2.jpg">
              <p>Mẹ trồng trọt, con gái và chồng phát triển thương hiệu <br> / Hai mẹ con tiếp tục công việc kinh doanh của gia đình bằng nghề nông Cheonma</p></div>
          </a></li>
          <li data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate"><a>
            <div class="img"><img src="img/main/img_life3.jpg">
              <p>Nuôi dưỡng ước mơ của bạn với trang trại thông minh<br>Nông dân trẻ Son Kyung-taek</p></div>
          </a></li>
        </ul>
      </div>
    </div>

    <div class="shim">
      <div class="inner">
        <h2 data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><strong>nghỉ ngơi</strong>địa điểm</h2>
        <div class="latest-box">
          <ul>
            <li data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><a>chuông gió</a></li>
            <li data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><a>Tính chính trực của KRC</a></li>
            <li data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate"><a>Sự đồng cảm của thế hệ VS 2040</a></li>
            <li data-aos="fade-up" data-aos-delay="500" class="aos-init aos-animate"><a>Viết ngôn ngữ công cộng ngay lập tức</a></li>
            <li data-aos="fade-up" data-aos-delay="500" class="aos-init aos-animate"><a>Kéo dãn đảm bảo giấc ngủ ngon trong đợt nắng nóng</a>
            </li>
          </ul>
          <div class="img-box">
            <a data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><img
                src="img/main/img_shim1.png"></a>
            <a data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate"><img
                src="img/main/img_shim2.png"></a>
          </div>
        </div>
        <div class="latest-box last">
          <ul>
            <li data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><a>Hướng dẫn đời sống văn hóa tháng 8</a></li>
            <li data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><a>KRC – YouTube</a></li>
            <li data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><a>Tin tức KRC</a></li>
            <li data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate"><a>tiểu luận của người đọc</a></li>
            <li data-aos="fade-up" data-aos-delay="500" class="aos-init aos-animate"><a>hộp thư KRC</a></li>
            <li data-aos="fade-up" data-aos-delay="400" class="aos-init aos-animate"><a>sự kiện độc giả</a></li>
          </ul>
          <div class="img-box">
            <a data-aos="fade-up" data-aos-delay="200" class="aos-init aos-animate"><img
                src="img/main/img_shim3.png"></a>
            <a data-aos="fade-up" data-aos-delay="300" class="aos-init aos-animate"><img
                src="img/main/img_shim4.png"></a>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 60px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toLottery() {
      if (!localStorage.getItem('token')) {
        this.$router.push({path: '/Login'})
      } else {
        this.$router.push({path: '/Home'})
      }
    },
  },
  mounted() {

  },
  created() {
  }
}

</script>

<style scoped>
</style>
